import React, { useEffect } from 'react'

import styled from 'styled-components'
import certificateImg from 'src/images/certificate.png'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Button } from '../ui/Button'
import { certificatesApi } from '../features/api'
import { appActions } from '../features/app/appSlice'

export const CertificatesPage = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(appActions.setActiveTabbarTab('certificates'))
  }, [])

  const {
    data, isPending
  } = useQuery({
    queryKey: ['certificates'],
    queryFn: () => certificatesApi.getCertificates(),
  })

  const handleBuyCertificate = (certificate) => {
    navigate(`/order/${certificate.id}`, { state: { data: certificate } })
  }

  return (
    <SCertificatesPage>
      <STitle>
        Номинальные электронные сертификаты
      </STitle>

      {
        !!data && data.map((certificate) => (
          <SCertificateCard
            key={certificate.id}
            onClick={() => handleBuyCertificate(certificate)}
          >
            <STopRow>
              <SCertificateImg src={certificateImg} />

              <STopRight>
                <SPrice>
                  {certificate.price} руб.
                </SPrice>

                <SDescription>
                  {certificate.description}
                </SDescription>
              </STopRight>
            </STopRow>

            <SButton>Купить</SButton>
          </SCertificateCard>
        ))
      }


    </SCertificatesPage>
  )
}

const SCertificatesPage = styled.div`
    padding: 24px 16px 70px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const STitle = styled.p`
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: white;
    max-width: 280px;
`

const SCertificateCard = styled.div`
    width: 100%;
    background: #212327;
    padding: 16px;
    border-radius: 20px;
    
    & ~ & {
        margin-top: 16px;
    }
`

const STopRow = styled.div`
    display: flex;
    align-items: center;
`

const SCertificateImg = styled.img`
    width: 96px;
    height: 64px;
`

const STopRight = styled.div`
    margin-left: 15px;
`

const SPrice = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: white;
`

const SDescription = styled.p`
    margin-top: 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
    color: white;

`

const SButton = styled(Button)`
    width: 100%;
    margin-top: 12px;
    height: 44px;
`
