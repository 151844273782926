import React, { useState } from 'react'

import certificateImg from 'src/images/certificate.png'
import { Counter } from 'src/ui/Counter'
import {
  InputGroupIOS, InputIOS
} from 'src/ui/InputIOS'
import styled from 'styled-components'
import {
  useLocation, useParams
} from 'react-router-dom'

import { Button } from '../ui/Button'
import { formatPrice } from '../utils/formatters'
import { certificatesApi } from '../features/api'
import {
  getUserData, getUserId 
} from '../features/app/selectors'

export const BasketPage = ({ className = '' }) => {
  const { id } = useParams()
  const { data } = useLocation().state
  const userId = getUserId()
  
  const defaultUserData = getUserData()

  const [userData, setUserData] = useState({
    name: defaultUserData?.name || '',
    fname: defaultUserData?.fname || '',
    phone: defaultUserData?.phone || '',
    email: defaultUserData?.email || ''
  })

  const handleBuy = async () => {
    const data = {
      userId,
      phoneNumber: userData.phone,
      name: userData.name,
      fname: userData.fname,
      email: userData.email,
      certificateId: id
    }

    const response = await certificatesApi.buyCertificate(data)

    window.location.href = response.paymentLink
  }

  return (
    <BasketPageBox className={className}>
      <OrderBox>
        <TitleRow>
          <Title>Покупка</Title>
        </TitleRow>

        <ProductRow>
          <ProductImgBox>
            <ProductImg src={certificateImg} />
          </ProductImgBox>

          <ProductInfo>
            <ProductTitle>{data.name}</ProductTitle>

            {/*<PriceRow>*/}
            {/*  <Price>{formatPrice(data.price)} ₽</Price>*/}

            {/*  <StyledCounter*/}
            {/*    size="small"*/}
            {/*    count={1}*/}
            {/*    onPlus={() => undefined}*/}
            {/*    onMinus={() => undefined}*/}
            {/*  />*/}
            {/*</PriceRow>*/}
          </ProductInfo>
        </ProductRow>

        <TotalRow>
          Итого:
          <span>{formatPrice(data.price)} ₽</span>
        </TotalRow>
      </OrderBox>

      <InputGroupLabel>
        Получатель
      </InputGroupLabel>
      <StyledInputGroupIOS>
        <InputIOS
          label="Имя"
          value={userData.name}
          onChange={(e) => {
            const regexp = /[^a-zа-яё_\-\/=\s]/gi
            const value = e.target.value
            setUserData(state => ({
              ...state,
              name: value.replace(regexp, '').substring(0, 25)
            }))}}
        />
        <InputIOS
          label="Фамилия"
          value={userData.fname}
          onChange={(e) => {
            const regexp = /[^a-zа-яё_\-\/=\s]/gi
            const value = e.target.value
            setUserData(state => ({
              ...state,
              fname: value.replace(regexp, '').substring(0, 25)
            }))}}
        />
        <InputIOS
          label="Телефон"
          value={userData.phone}
          mask="+79999999999"
          onChange={(e) => {
            const regexp = /[^0-9]/gi
            const value = e.target.value
            setUserData(state => ({
              ...state,
              phone: '+' + value.replace(regexp, '').substring(0, 11).trim()
            }))}}
        />
        <InputIOS
          label="Почта"
          value={userData.email}
          onChange={(e) => {
            const value = e.target.value
            setUserData(state => ({
              ...state,
              email: value
            }))}}
        />
      </StyledInputGroupIOS>

      <SButton
        onClick={handleBuy}
        disabled={!userData.name || !userData.fname || !userData.email || !userData.phone}
      >
        Перейти к оплате
      </SButton>
    </BasketPageBox>
  )
}

const BasketPageBox = styled.div`
  min-height: 100vh;
  padding: 16px 8px 85px;
  display: flex;
  flex-direction: column;
`

const OrderBox = styled.div`
  width: 100%;
  background: #212327;
  border-radius: 8px;
  padding: 16px;
`

const Title = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: white;
`

const ProductRow = styled.div`
  display: flex;

  & ~ & {
    margin-top: 24px;
  }
`

const ProductImgBox = styled.div`
  width: 48px;
  min-width: 48px;
  height: 32px;
`

const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ProductInfo = styled.div`
  margin-left: 16px;
  width: 100%;
`

const ProductTitle = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: white;
`

const PriceRow = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
`

const Price = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

`

const ProductDescription = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};
`

const TotalRow = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 5px;
  border-top: 0.33px solid rgba(60, 60, 67, 0.29);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: white;
`

const StyledInputGroupIOS = styled(InputGroupIOS)`
  
`

const InputGroupLabel = styled.p`
  margin-top: 28px;
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #636366;
  text-transform: uppercase;
`

const StyledCounter = styled(Counter)`
  margin-left: auto;
    
    span {
        color: #7E7E82;
    }
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`

const SButton = styled(Button)`
    margin-top: auto;
    height: 48px;
`
