import React, {
  useEffect, useState
} from 'react'

import styled from 'styled-components'
import keyImg from 'src/images/key.png'
import closedCaseImg from 'src/images/case-closed.png'
import emptyCaseImg from 'src/images/case-empty.png'
import successCaseImg from 'src/images/case-success.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '../ui/Button'
import {
  appActions, getAttemptsCountThunk, getOpportunityToPlayTodayThunk
} from '../features/app/appSlice'
import { certificatesApi } from '../features/api'
import {
  getCanUserPlayToday,
  getUserAttemptsCount, getUserId
} from '../features/app/selectors'

const arrWith3Items = Array.from({ length:3 })

export const GamePage = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userId = getUserId()
  const attemptsCount = getUserAttemptsCount()
  const canPlayToday = getCanUserPlayToday()

  const [chosenCaseIndex, setChosenCaseIndex] = useState()
  const [inProgress, setInProgress] = useState(false)
  const [prizeData, setPrizeData] = useState(null)

  const casesImgs = arrWith3Items.map((_, index) => {
    if (chosenCaseIndex === index) {
      if (prizeData?.prize) {
        return successCaseImg
      }

      if (prizeData && !prizeData?.prize) {
        return emptyCaseImg
      }
    }

    return closedCaseImg
  })

  useEffect(() => {
    dispatch(appActions.setActiveTabbarTab('game'))
  }, [])

  const handleBuyClick = () => {
    navigate('/certificates')
  }

  const handleClose = () => {
    navigate('/')
  }

  const handleCaseClick = async (index) => {
    if (inProgress || !canPlayToday) {
      return
    }

    setInProgress(true)
    setChosenCaseIndex(index)

    const response = await certificatesApi.play(userId)

    if (!response.wonPrize) {
      setPrizeData({ prize: null })
    } else {
      setPrizeData({ prize: response.wonPrize })
    }

    dispatch(getAttemptsCountThunk())
    dispatch(getOpportunityToPlayTodayThunk())
  }

  return (
    <SGamePage>
      <STitle>
        Откройте сундук ключом и выиграйте приз!
      </STitle>
      <SCardBox>
        <SKeysCard>
          <SCardTop>
            <SCardLeft>
              У вас есть попыток: {attemptsCount} <br /> <br />
              В день можно сыграть только один раз
            </SCardLeft>

            <SKeyImgBox>
              <SKeyImg src={keyImg} />
            </SKeyImgBox>
          </SCardTop>
        </SKeysCard>
      </SCardBox>

      <SOpenDescription>
        Тыкните на сундук, чтобы открыть его
      </SOpenDescription>

      <SCasesBox>
        {casesImgs.map((img, index) => (
          <SCaseImg
            src={img}
            key={img + index}
            onClick={() => handleCaseClick(index)}
          />
        ))}
      </SCasesBox>

      {
        !!prizeData && (
          <SResultBox>
            {!prizeData.prize && (<SBannerTitle>
              Увы! <br />
              Сундук оказался пустым
            </SBannerTitle>)
            }

            {!!prizeData.prize && (
              <>
                <SBannerTitle>
              Ура!
                  <br />
              Удачно открыли сундук!
                </SBannerTitle>

                <SGiftTitle>
                  Ваш выигрыш
                </SGiftTitle>

                <SGiftName>
                  {prizeData.prize.name}
                </SGiftName>

                <SGiftImage src={prizeData.prize?.image?.url} />
              </>
            )}


            {!attemptsCount && !prizeData.prize && (
              <>
                <SNotEnoughKeys>
          У вас закончились ключи
                </SNotEnoughKeys>
                <SResultBuyCertificateButton onClick={handleBuyClick}>
                Купить сертификат
                </SResultBuyCertificateButton>
              </>)
            }

            <SPlayCloseButton onClick={handleClose}>Закрыть</SPlayCloseButton>
          </SResultBox>
        )
      }
    </SGamePage>
  )
}

const SGamePage = styled.div`
    padding-top: 16px;
    height: 100vh;
    background: #212327;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const STitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: white;
  max-width: 330px;
`

const SCardBox = styled.div`
    padding: 0 16px;
`

const SKeysCard = styled.div`
    margin-top: 16px;
    padding: 16px;
    background: black;
    width: 100%;
    border-radius: 10px;
`

const SCardTop = styled.div`
    display: flex;
`

const SCardLeft = styled.div`
    margin-right: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: white;
`

const SKeyImgBox = styled.div`
    margin-left: auto;
    width: 60px;
    height: 60px;
    min-width: 60px;
`

const SKeyImg = styled.img`
    width: 100%;
    height: 100%;
`

const SOpenDescription = styled.p`
  margin-top: 28px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: white;
  max-width: 170px;
`

const SCasesBox = styled.div`
  margin-top: 40px;
  width: 332px;
  display: flex;
  justify-content: space-between;
`

const SCaseImg = styled.img`
  width: 104px;
  height: 104px;
`

const SBannerTitle = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: white;
`

const SResultBox = styled.div`
    width: 100vw;
    min-height: 200px;
    background: linear-gradient(170.41deg, #4158D0 -20.37%, #C850C0 20.28%, #FFCC70 75.27%);
    position: fixed;
    bottom: 64px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const SPlayCloseButton = styled(Button)`
  margin-top: 30px;
  width: 240px;
`

const SNotEnoughKeys = styled.p`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: white;
`

const SResultBuyCertificateButton = styled(Button)`
  margin-top: 24px;
  width: 240px;
`

const SGiftTitle = styled.p`
  margin-top: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: white;
`

const SGiftName = styled.p`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: white;
`

const SGiftImage = styled.img`
  margin-top: 18px;
  width: 150px;
  height: 150px;
  object-fit: contain;
`
