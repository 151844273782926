import React from 'react'

import { ProfileIcon } from './ProfileIcon'
import { TicketIcon } from './TicketIcon'
import { PuzzleIcon } from './PuzzleIcon'
import { ArrowIcon } from './ArrowIcon'

export const SvgIcon = ({
  type, ...props 
}) => {
  switch(type) {
    case 'profile':
      return <ProfileIcon {...props} />
    case 'ticket':
      return <TicketIcon {...props} />
    case 'puzzle':
      return <PuzzleIcon {...props} />
    case 'arrow':
      return <ArrowIcon {...props} />
    default:
      return (
        <>
Icon not found
        </>
      )
  }
}
