import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import keyImg from 'src/images/key.png'
import casesImg from 'src/images/home-cases.png'
import casesClosedImg from 'src/images/home-cases-closed.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '../ui/Button'
import { appActions } from '../features/app/appSlice'
import { certificatesApi } from '../features/api'
import {
  getCanUserPlayToday, getUserAttemptsCount, getUserId
} from '../features/app/selectors'

export const HomePage = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userId = getUserId()
  const canPlayToday = getCanUserPlayToday()
  const attemptsCount = getUserAttemptsCount()

  useEffect(() => {
    dispatch(appActions.setActiveTabbarTab('game'))
  }, [])

  const handleBuyClick = () => {
    navigate('/certificates')
  }

  const handleGameClick = () => {
    navigate('/game')
  }

  return (
    <SHomePage>
      <SCardBox>
        <SKeysCard>
          <SCardTop>
            <SCardLeft>
              <SCardTitle>
            Каждый ключ - это одна попытка
              </SCardTitle>

              <SCardDescription>
            Купите сертификат, чтобы открыть сундук
              </SCardDescription>
            </SCardLeft>

            <SKeyImgBox>
              <SKeyImg src={keyImg} />

              <SAttemptsCount>
                {attemptsCount || 0}
              </SAttemptsCount>
            </SKeyImgBox>
          </SCardTop>

          <SCardBtn onClick={handleBuyClick}>
            {attemptsCount > 0 ? 'Получите больше ключей' : 'Купить сертификат'}
          </SCardBtn>
        </SKeysCard>
      </SCardBox>

      <SPlayBannerBox>
        <SBannerTitle>
          Откройте сундук ключом и выиграйте приз!
        </SBannerTitle>

        {attemptsCount > 0 && (
          <SCasesImg src={casesImg} />
        )}

        {!attemptsCount && (
          <>
            <SCasesImg src={casesClosedImg} />
            <SEmptyText>
              У вас 0 попыток <br />
            Чтобы начать игру вам нужно купить сертификат
            </SEmptyText>
            <SPlayBtn onClick={handleBuyClick}>Купить сертификат</SPlayBtn>
          </>
        )}

        {canPlayToday && attemptsCount > 0 && (
          <SPlayBtn onClick={handleGameClick}>Играть</SPlayBtn>
        )
        }

        {!canPlayToday && attemptsCount > 0 && (
          <SWaitDescription>
            Заходи завтра, <br />
            чтобы играть снова
          </SWaitDescription>
        )}
      </SPlayBannerBox>
    </SHomePage>
  )
}

const SHomePage = styled.div`
    padding-top: 16px;
    height: 100vh;
`

const SCardBox = styled.div`
    padding: 0 16px;
`

const SKeysCard = styled.div`
    padding: 16px;
    background: #212327;
    width: 100%;
    border-radius: 10px;
`

const SCardTop = styled.div`
    display: flex;
`

const SCardLeft = styled.div`
    margin-right: 12px;
`

const SCardTitle = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: white;
`

const SCardDescription = styled.p`
    margin-top: 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    text-align: left;
    color: white;

`

const SCardBtn = styled(Button)`
    width: 100%;
    margin-top: 16px;
`

const SKeyImgBox = styled.div`
    margin-left: auto;
    width: 60px;
    min-width: 60px;
    height: 60px;
    position: relative;
`

const SAttemptsCount = styled.div`
    width: 24px;
    height: 24px;
    background: #526AFF;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: -10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

`

const SKeyImg = styled.img`
    width: 100%;
    height: 100%;
`

const SPlayBannerBox = styled.div`
    width: 100vw;
    background: linear-gradient(170.41deg, #4158D0 -20.37%, #C850C0 20.28%, #FFCC70 75.27%);
    height: min(518px, 64vh);
    position: fixed;
    bottom: 64px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 40px 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const SBannerTitle = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: white;
`

const SCasesImg = styled.img`
    width: 320px;
    height: auto;
`

const SPlayBtn = styled(Button)`
  width: 240px;
`

const SEmptyText = styled.p`
  font-family: Stolzl;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #212327;
`

const SWaitDescription = styled.p`
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #212327;
`
