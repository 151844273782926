import React from 'react'

export const ProfileIcon = ({
  className = '',  ...props
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 25.3333C8 22.3878 11.5817 20 16 20C20.4183 20 24 22.3878 24 25.3333"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16.0002C18.9455 16.0002 21.3333 13.6123 21.3333 10.6668C21.3333 7.72131 18.9455 5.3335 16 5.3335C13.0544 5.3335 10.6666 7.72131 10.6666 10.6668C10.6666 13.6123 13.0544 16.0002 16 16.0002Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  
  )
}