/* eslint-disable max-len */

import { request } from './request'

export const certificatesApi = {
  authUser: (data) => request('POST', '/api/auth/telegram', data),
  getCertificates: () => request('GET', '/api/certificates'),
  buyCertificate: (data) => request('POST', '/api/certificates/purchase', data),
  getMyCertificates: (userId) => request('GET', '/api/purchase/my', { userId }),
  getWinnings: (userId) => request('GET', '/api/games', { userId }),
  play: (userId) => request('POST', '/api/games', { id: userId }),
  getAttemptsCount: (userId) => request('GET', '/api/games/attempts', { userId }),
  checkUsersOpportunityToPlayToday: (userId) => request('GET', '/api/games/check', { userId }),
}
