import {
  createAsyncThunk, createSlice
} from '@reduxjs/toolkit'

import { certificatesApi } from '../api'

const initialState = {
  userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id,
  activeTabbarTab: 'game',
  attemptsCount: 0,
  canPlayToday: false,
  userData: {}
}

export const getAttemptsCountThunk = createAsyncThunk(
  'app/getAttemptsCountThunk',
  async function(_, {
    getState, dispatch
  }) {
    const state = getState()
    const userId = state.app.userId

    const response = await certificatesApi.getAttemptsCount(userId)

    dispatch(appActions.setAttemptsCount(response.attempts))
  }
)

export const getOpportunityToPlayTodayThunk = createAsyncThunk(
  'app/getOpportunityToPlayTodayThunk',
  async function(_, {
    getState, dispatch
  }) {
    const state = getState()
    const userId = state.app.userId

    const response = await certificatesApi.checkUsersOpportunityToPlayToday(userId)

    if (response.message === 'Can game') {
      dispatch(appActions.setCanPlayToday(true))
    } else {
      dispatch(appActions.setCanPlayToday(false))
    }
  }
)

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setActiveTabbarTab: (state, action) => {
      state.activeTabbarTab = action.payload
    },
    setAttemptsCount: (state, action) => {
      state.attemptsCount = action.payload
    },
    setCanPlayToday: (state, action) => {
      state.canPlayToday = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    }
  },
})

export const appActions = appSlice.actions
export const appReducer = appSlice.reducer
