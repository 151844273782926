import React from 'react'

import styled from 'styled-components'
import InputMask from 'react-input-mask'

export const InputIOS = ({
  className = '', label, onChange, value, disabled, mask = '' 
}) => {
  return (
    <InputIOSBox className={className}>
      <InputLabel>{label}</InputLabel>
      {
        !!mask && (
          <InputMask
            mask={mask}
            value={value}
            onChange={onChange}
            maskChar=""
          >
            {(inputProps) => (
              <Input
                {...inputProps}
                placeholder={label}
              />
            )
            }
          </InputMask>
        )
      }

      {
        !mask && (
          <Input
            onChange={onChange}
            value={value}
            placeholder={label}
            disabled={disabled}
          />
        )
      }
      
    </InputIOSBox>
  )
}

const InputIOSBox = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  & ~ & {
    border-top: 0.33px solid rgba(60, 60, 67, 0.29);
  }
`

const InputLabel = styled.span`
  width: 30%;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: white;
`

const Input = styled.input`
  flex: 1;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  color: white;
  border: none;
  outline: none;
  background: transparent;

  &::placeholder {
    color: #FFFFFF99;
  }
`


export const InputGroupIOS = ({
  className = '', children 
}) => {
  return (
    <InputGroupIOSBox className={className}>
      {children}
    </InputGroupIOSBox>
  )
}

const InputGroupIOSBox = styled.div`
  width: 100%;
  background: #212327;
  border-radius: 8px;
`