import React, { useEffect } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Button } from '../ui/Button'

export const SuccessPaymentPage = () => {
  const navigate = useNavigate()

  const handleGameClick = () => {
    if (window?.Telegram?.WebApp?.initDataUnsafe?.user?.id) {
      window.location.href = '/'
    } else {
      window.location.href = 'https://t.me/ten_years_bsjsc_bot'
    }
  }

  const handleCertificatesClick = () => {
    if (window?.Telegram?.WebApp?.initDataUnsafe?.user?.id) {
      window.location.href = '/my-certificates'
    } else {
      window.location.href = 'https://t.me/ten_years_bsjsc_bot'
    }
  }

  return (
    <SPageBox>
      <STitle>Оплата прошла успешно</STitle>
      <SDescription>Сертификат появится в личном кабинете в течении 3-х минут, происходит проверка платежа.</SDescription>

      <SGameButton onClick={handleGameClick}>
        Перейти к игре
      </SGameButton>

      <SCertificatesButton onClick={handleCertificatesClick}>
        Мои сертификаты
      </SCertificatesButton>
    </SPageBox>
  )
}

const SPageBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: #212327;
  padding-top: 102px;
`

const STitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: white;
`

const SDescription = styled.p`
  max-width: 320px;
  margin-top: 80px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: white;
`

const SGameButton = styled(Button)`
  margin-top: 140px;
  width: 240px;
`

const SCertificatesButton = styled(Button)`
  margin-top: 24px;
  background: white;
  color: #212327;
  width: 240px;
`
