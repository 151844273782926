import React from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

import certificateImg from '../images/certificate.png'
import { Button } from '../ui/Button'
import { certificatesApi } from '../features/api'
import { getUserId } from '../features/app/selectors'

export const MyCertificatesPage = ({ className = '' }) => {
  const navigate = useNavigate()
  const userId = getUserId()

  const {
    data, isPending
  } = useQuery({
    queryKey: ['my-certificates'],
    queryFn: () => certificatesApi.getMyCertificates(userId),
  })

  const handleChoseCertificate = () => {
    navigate('/certificates')
  }

  const handleCertificateClick = (data) => {
    navigate(`/certificate/${data.id}`, { state: { data } })
  }

  return (
    <SMyCertificates className={className}>
      <STitle>
        Мои сертификаты
      </STitle>

      {
        !!data && data.length && data.map(certificate => (
          <SCertificateCard
            key={certificate.id}
            onClick={() => handleCertificateClick(certificate)}
          >
            <STopRow>
              <SCertificateImg src={certificateImg} />

              <STopRight>
                <SPrice>
                  {certificate?.code?.certificate?.price} рублей
                </SPrice>

                <SDescription>
                  покупка от {moment(certificate?.createdAt).format('DD.MM.yyyy')}
                </SDescription>
              </STopRight>
            </STopRow>
          </SCertificateCard>
        ))
      }

      { !isPending && !data?.length && (
        <SEmptyBox>
          <SEmptyTitle>
          У вас пока нет сертификатов
          </SEmptyTitle>

          <SEmptyButton onClick={handleChoseCertificate}>
          Выбрать сертификат
          </SEmptyButton>
        </SEmptyBox>
      )}
    </SMyCertificates>
  )
}

const SMyCertificates = styled.div`
  padding: 24px 16px 90px;
`

const STitle = styled.p`\
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: white;
`

const SCertificateCard = styled.div`
    width: 100%;
    background: #212327;
    padding: 16px;
    border-radius: 20px;
    
    & ~ & {
        margin-top: 16px;
    }
`

const STopRow = styled.div`
    display: flex;
    align-items: center;
`

const SCertificateImg = styled.img`
    width: 56px;
    height: 56px;
    object-fit: contain;
`

const STopRight = styled.div`
    margin-left: 15px;
`

const SPrice = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: white;
`

const SDescription = styled.p`
    margin-top: 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
    color: white;
`

const SEmptyBox = styled.div`
    padding-top: 136px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SEmptyTitle = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: white;
`

const SEmptyButton = styled(Button)`
    margin-top: 40px;
`
