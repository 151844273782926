import { useSelector } from 'react-redux'

export const getUserId = () => useSelector(state => state.app.userId)

export const getActiveTabbarTab = () => useSelector(state => state.app.activeTabbarTab)

export const getUserAttemptsCount = () => useSelector(state => state.app.attemptsCount)

export const getCanUserPlayToday = () => useSelector(state => state.app.canPlayToday)

export const getUserData = () => useSelector(state => state.app.userData)
