import React from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import emptyCaseImg from 'src/images/case-empty.png'

import { Button } from '../ui/Button'
import { getUserId } from '../features/app/selectors'
import { certificatesApi } from '../features/api'

export const WinningsPage = ({ className = '' }) => {
  const navigate = useNavigate()
  const userId = getUserId()

  const {
    data, isPending
  } = useQuery({
    queryKey: ['my-winnings'],
    queryFn: () => certificatesApi.getWinnings(userId),
  })

  const handleChoseCertificate = () => {
    navigate('/certificates')
  }

  const handlePrizeClick = (data) => {
    navigate(`/winning/${data.id}`, { state: { data } })
  }

  return (
    <SWinningsPage className={className}>
      <STitle>
        Мои выигрыши
      </STitle>

      {
        !!data && data.length && data.map(prize => prize?.prize ? (
          <SWinningCard
            key={prize.id}
            onClick={() => handlePrizeClick(prize)}
          >
            <SWinningImg src={prize?.prize?.image?.url} />

            <SDescription>
              {prize?.prize?.name}
            </SDescription>
          </SWinningCard>
        ) : (
          <SWinningCard className='disabled'>
            <SWinningImg src={emptyCaseImg} />

            <SDescription>
              Сундук оказался пустым
            </SDescription>
          </SWinningCard>
        ))}

      { !isPending && !data?.length && (
        <SEmptyBox>
          <SEmptyTitle>
          У вас пока нет выигрышей
          </SEmptyTitle>

          <SEmptyButton onClick={handleChoseCertificate}>
          Играть
          </SEmptyButton>
        </SEmptyBox>
      )}
    </SWinningsPage>
  )
}

const SWinningsPage = styled.div`
  padding: 24px 16px 90px;
`

const STitle = styled.p`\
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: white;
`

const SWinningCard = styled.div`
    width: 100%;
    background: #212327;
    padding: 16px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    
    & ~ & {
        margin-top: 16px;
    }
  
  &.disabled {
    opacity: 0.7;
  }
`

const SWinningImg = styled.img`
    width: 80px;
    height: 80px;
    object-fit: contain;
`

const SDescription = styled.p`
    margin-left: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: white;

`

const SEmptyBox = styled.div`
    padding-top: 136px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SEmptyTitle = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: white;
`

const SEmptyButton = styled(Button)`
    margin-top: 40px;
`
