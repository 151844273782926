import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import {
  Routes, Route
} from 'react-router-dom'
import {
  QueryClient, QueryClientProvider
} from '@tanstack/react-query'

import { ScrollToTopWhenLocationChanged } from './features/app/ScrollToTopWhenLocationChanged'
import { BasketPage } from './pages/BasketPage'
import { HomePage } from './pages/HomePage'
import { GlobalStyle } from './ui/styled.global'
import { ProfilePage } from './pages/ProfilePage'
import { Tabbar } from './features/app/Tabbar'
import { CertificatesPage } from './pages/CertificatesPage'
import { MyCertificatesPage } from './pages/MyCertificatesPage'
import { CertificatePage } from './pages/CertificatePage'
import { WinningsPage } from './pages/WinningsPage'
import { WinningPage } from './pages/WinningPage'
import { SuccessPaymentPage } from './pages/SuccessPaymentPage'
import { GamePage } from './pages/GamePage'
import {
  appActions,
  getAttemptsCountThunk, getOpportunityToPlayTodayThunk
} from './features/app/appSlice'
import { certificatesApi } from './features/api'

const queryClient = new QueryClient()

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAttemptsCountThunk())
    dispatch(getOpportunityToPlayTodayThunk())
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTopWhenLocationChanged>
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />

          <Route
            path="/game"
            element={<GamePage />}
          />

          <Route
            path="/certificates"
            element={<CertificatesPage />}
          />
          <Route
            path="/order/:id"
            element={<BasketPage />}
          />

          <Route
            path="/profile"
            element={<ProfilePage />}
          />
          <Route
            path="/my-certificates"
            element={<MyCertificatesPage />}
          />
          <Route
            path="/certificate/:id"
            element={<CertificatePage />}
          />
          <Route
            path="/winnings"
            element={<WinningsPage />}
          />
          <Route
            path="/winning/:id"
            element={<WinningPage />}
          />

          <Route
            path="/success_order"
            element={<SuccessPaymentPage />}
          />
        </Routes>

        <Tabbar />
        <GlobalStyle />
      </ScrollToTopWhenLocationChanged>
    </QueryClientProvider>
  )
}

const WithAuthorization = ({ children }) => {
  const dispatch = useDispatch()
  
  const [authorized, setAuthorized] = React.useState(false)
  
  useEffect(async () => {
    const initData = window.Telegram.WebApp.initData
    
    const response = await certificatesApi.authUser({ initData })
    
    localStorage.setItem('token', response.jwt)
    dispatch(appActions.setUserData(response.user))
    
    setAuthorized(true)
  }, [])
  
  if (!authorized) {
    return null
  }
  
  return (
    children
  )
}

export default () => {
  return (
    <WithAuthorization>
      <App />
    </WithAuthorization>
  )
}
