import React from 'react'

export const PuzzleIcon = ({
  className = '',  ...props
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.6667 8C22.6667 8.46751 22.5865 8.91629 22.4391 9.33333L26.6667 9.33333C27.403 9.33333 28 9.93029 28 10.6667V14.8943C27.583 14.7469 27.1342 14.6667 26.6667 14.6667C24.4575 14.6667 22.6667 16.4575 22.6667 18.6667C22.6667 20.8758 24.4575 22.6667 26.6667 22.6667C27.1342 22.6667 27.583 22.5865 28 22.4391V26.6667C28 27.403 27.403 28 26.6667 28H10.6667C9.93029 28 9.33334 27.403 9.33334 26.6667V22.4391C8.9163 22.5865 8.46751 22.6667 8 22.6667C5.79086 22.6667 4 20.8758 4 18.6667C4 16.4575 5.79086 14.6667 8 14.6667C8.46751 14.6667 8.9163 14.7469 9.33334 14.8943V10.6667C9.33334 9.93029 9.93029 9.33333 10.6667 9.33333L14.8943 9.33333C14.7469 8.91629 14.6667 8.46751 14.6667 8C14.6667 5.79086 16.4575 4 18.6667 4C20.8758 4 22.6667 5.79086 22.6667 8Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  
  )
}