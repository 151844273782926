import React from 'react'

import styled from 'styled-components'
import classNames from 'classnames'

import { ReactComponent as MinusIcon } from '@/images/icons/minus.svg'
import { ReactComponent as PlusIcon } from '@/images/icons/plus.svg'

export const Counter = ({
  className, count, onMinus, onPlus, size
}) => {
  const handleMinusClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onMinus(e)
  }

  const handlePlusClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onPlus(e)
  }

  return (
    <CounterBox className={classNames(className, { small: size === 'small' })}>
      <ControlBox onClick={handleMinusClick}>
        <MinusIcon />
      </ControlBox>

      <Count>{count}</Count>

      <ControlBox onClick={handlePlusClick}>
        <PlusIcon />
      </ControlBox>
    </CounterBox>
  )
}

const CounterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 96px;
  height: 40px;
`

const ControlBox = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #7E7E82;
    
    svg {
        width: 14px;
        height: 14px;
    }
`

const Count = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: white;
  width: 20px;
`