import React from 'react'

import classNames from 'classnames'
import {
  useLocation, useNavigate
} from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import styled from 'styled-components'
import { SvgIcon } from 'src/ui/svg/SvgIcon'

import { getActiveTabbarTab } from './selectors'


export const Tabbar = ({ className = '' }) => {
  const navigate = useNavigate()
  const activeTab = getActiveTabbarTab()
 
  const handleCertificatesClick = () => {
    navigate(ROUTES.certificates)
  }
 
  const handleGameClick = () => {
    navigate(ROUTES.home)
  }
 
  const handleProfileClick = () => {
    navigate(ROUTES.profile)
  }
  
  const tabs = [
    {
      label: 'Сертфикаты', url: ROUTES.certificates, icon: 'ticket', onClick: handleCertificatesClick, value: 'certificates'
    },
    {
      label: 'Игра', url: ROUTES.home, icon: 'puzzle', onClick: handleGameClick, value: 'game'
    },
    {
      label: 'Профиль', url: ROUTES.home, icon: 'profile', onClick: handleProfileClick, value: 'profile'
    },
  ]
  
  return (
    <STabbar>
      {tabs.map(tab => (
        <STab
          key={tab.label}
          onClick={tab.onClick}
          className={classNames({ active: activeTab === tab.value })}
        >
          
          <SSvgIcon type={tab.icon} />
          
          {tab.label}
          
        </STab>
      ))}
    </STabbar>
  )
}

const STabbar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  background: #212327;
  border-top: 0.5px solid #57585A;
`

const STab = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  font-weight: 300;
  line-height: 8px;
  color: #D2D2D2;
    
    &.active {
        color: #526AFF;
    }
`

const SSvgIcon = styled(SvgIcon)`
  margin-bottom: 4px;
`