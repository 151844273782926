import React, { useEffect } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { SvgIcon } from '../ui/svg/SvgIcon'
import { appActions } from '../features/app/appSlice'

export const ProfilePage = ({ className = '' }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(appActions.setActiveTabbarTab('profile'))
  }, [])

  const handleMyCertificatesClick = () => {
    navigate('/my-certificates')
  }

  const handleMyWinningsClick = () => {
    navigate('/winnings')
  }

  return (
    <ProfileBox className={className}>
      <SLink onClick={handleMyCertificatesClick}>
        Мои сертификаты

        <SvgIcon type='arrow' />
      </SLink>

      <SLink onClick={handleMyWinningsClick}>
        Мои выигрыши

        <SvgIcon type='arrow' />
      </SLink>
    </ProfileBox>
  )
}

const ProfileBox = styled.div`
  padding: 24px 16px 90px;
`

const SLink = styled.div`
    width: 100%;
    background: #212327;
    height: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: white;
    
    & ~ & {
        margin-top: 8px;
    }
`
