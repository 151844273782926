import React from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

export const Button = ({
  className = '', children, onClick, disabled 
}) => {
  return (
    <ButtonBox
      onClick={onClick}
      className={classNames(className, { disabled })}
    >
      {children}
    </ButtonBox>
  )
}

const ButtonBox = styled.div`
  background: #526AFF;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }

  &.disabled {
    background: #7E7E82;
    border-radius: 8px;
  }
`